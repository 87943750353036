import AuthProvider from "context/AuthContext";
import ProjectProvider from "context/ProjectContext";
import ForgotPassword from "pages/forgot-password";
import Login from "pages/login";
import ResetPassword from "pages/reset-password";
import CreateAccount from "pages/create-account";
import { Provider } from "react-redux";
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from "react-router-dom";
import { store } from "store";
import VerifyEmail from "pages/verify-email";
import Products from "pages/products";
import Profile from "pages/profile";
import ProcessLogin from "pages/process-login";
import NotFound from "pages/404";
import DeleteRequest from "pages/account-delete-request";
import EpicxplorerSSO from "pages/sso/epicxplorer";
// import CrispHelp from "components/CrispHelp";
import PostHogRegister from "components/PostHogRegister";
import NavLayout from "components/layouts/NavLayout";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <Provider store={store}>
          <AuthProvider>
            <ProjectProvider>
              <Outlet />
              {/* <CrispHelp /> */}
              <PostHogRegister />
            </ProjectProvider>
          </AuthProvider>
        </Provider>
      }
    >
      <Route path="/">
        <Route path="" element={<Navigate to={"/products"} />} />
        <Route element={<NavLayout />}>
          <Route path="/products" element={<Products />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="/account-delete-request" element={<DeleteRequest />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/sso/epicxplorer" element={<EpicxplorerSSO />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:hash" element={<ResetPassword />} />
      <Route path="/verify-email/:verifyHash" element={<VerifyEmail />} />
      <Route path="/process-login/:loginToken" element={<ProcessLogin />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
